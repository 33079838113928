
import { Component, Vue } from 'vue-property-decorator'
import { initWangEdit } from '@/utils/wangEdit'
import UploadFile from '@/components/uploadFile/Index.vue'
import { ElForm } from 'element-ui/types/form'
import { Info } from '../../types/mechanics'
import { FileInfo } from '@/types/common'

@Component({
  name: 'mechanicsAdd',
  components: { UploadFile }
})
export default class extends Vue {
  private formData: Info = {
    machineName: '',
    machineAlias: '',
    useMethod: '',
    resourceList: []
  }

  private formRules = {
    machineName: [
      { required: true, message: '请输入机械名称', trigger: ['blur', 'change'] }
    ],
    resourceList: [{ required: true, message: '请上传图片', trigger: ['change'] }],
    useMethod: [
      { required: true, message: '请输入内容', trigger: ['blur', 'change'] }
    ]
  }

  private title = '新增'
  private editor: any = null
  private submitShow = false

  get manageId () {
    return this.$route.params.id || ''
  }

  created () {
    this.initRichText()
    if (this.manageId !== '') {
      this.title = '编辑'
      this.getDetail()
    }
  }

  onSuccess (file: FileInfo) {
    this.formData.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.formData.resourceList.splice(index, 1)
  }

  // 编辑获取详情
  getDetail () {
    this.$axios.get(this.$apis.mechanics.selectMachineByManageId, {
      manageId: this.manageId
    }).then(res => {
      this.formData = res
      this.editor.txt.html(res.useMethod)
      console.log(' this.editor: ', this.editor)
    })
  }

  // 初始化富文本
  initRichText () {
    this.$nextTick(() => {
      this.editor = initWangEdit('#informationRichText', { placeholder: '请输入使用方法' })
      this.editor.create()
    })
  }

  // 保存
  onSubmit () {
    this.formData.useMethod = this.editor.txt.html()
    const url = this.manageId
      ? this.$apis.mechanics.updateMachine
      : this.$apis.mechanics.insertMachine;
    (this.$refs.formData as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(url, this.formData
        ).then(() => {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.$router.push({ name: 'mechanicsList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  destroyed () {
    // 销毁编辑器
    this.editor.destroy()
    this.editor = null
  }
}
